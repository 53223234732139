import axios from "axios";
import { createContext, useEffect, useState } from "react";
import {
  ADD_TO_CART,
  ADD_WISHLIST,
  AUTH_TOKEN,
  DELETE_WISHLIST,
  GET_CART_DATA,
  GET_WISHLIST,
  REMOVE_CART_DATA,
  USER_LOGOUT,
} from "../components/Apis";
import { toast } from "react-toastify";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  // FETCH USER DATA
  const [itemCount, setItemCount] = useState(0);
  const [cartData, setCartData] = useState(null);
  const [checkoutData, setCheckoutData] = useState(null);
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [isOtpModal, setIsOtpModal] = useState(false);
  const [cartLoader, setCartLoader] = useState(false);
  let accessToken = localStorage.getItem("BandhejHub");

  // check token is expired or not
  const isTokenExpired = (token) => {
    try {
      const tokenParts = token.split(".");
      if (tokenParts?.length !== 3) {
        throw new Error("Invalid token format");
      }

      // Decode the payload part (base64)
      const payload = JSON.parse(atob(tokenParts[1]));

      // Get the current time in seconds
      const currentTime = Math.floor(Date.now() / 1000);

      // Compare the current time with the token's expiration time
      if (payload.exp && payload.exp < currentTime) {
        return true; // Token is expired
      } else {
        return false; // Token is valid
      }
    } catch (error) {
      return true; // Treat as expired if there's an error
    }
  };

  // {REFRESH TOKEN}
  const refreshAccessToken = async () => {
    try {
      const response = await axios.post(
        AUTH_TOKEN,
        {},
        { withCredentials: true }
      );

      // if refresh token is expire
      const { accessToken } = response.data;
      localStorage.setItem("BandhejHub", accessToken);
      return accessToken;
    } catch (error) {
      if (error.response.status === 404) {
        toast.error("Oops! Session has expired");
        localStorage.removeItem("BandhejHub");
        window.alert("Session has expired");
        window.location.href = "/";
      } else if (error.response.status === 401) {
        toast.error("Oops! Session has expired");
        window.alert("Session has expired");
        await axios.post(
          USER_LOGOUT,
          {},
          {
            withCredentials: true,
          }
        );
        localStorage.removeItem("BandhejHub");
        window.location.href = "/";
      }
      console.log(error.response.status);
    }
  };

  const handleSignOut = async () => {
    const logout = window.confirm("Are you sure you want to log out?");
    if (logout) {
      try {
        await axios.post(USER_LOGOUT, {}, { withCredentials: true });
        localStorage.removeItem("BandhejHub");
        window.location.href = "/";
      } catch (error) {
        if (error.response.status === 404) {
          localStorage.removeItem("BandhejHub");
          window.location.href = "/";
        }
      }
    }
  };

  //ADD TO CART
  const handleAddToCart = async (quantity, sku) => {
    setCartLoader(true);
    const addToCartData = { quantity, sku };
    if (!accessToken) {
      document.body.style.overflow = "hidden";
      setIsLoginOpen(true);
      toast.error("Please Login for add product in cart");
      setCartLoader(false);
    } else {
      try {
        if (isTokenExpired(accessToken))
          accessToken = await refreshAccessToken();

        await axios.post(ADD_TO_CART, addToCartData, {
          headers: { Authorization: `Bearer ${accessToken}` },
          withCredentials: true,
        });
        fetchCartData();
        toast.success("Product added successfully");
        setCartLoader(false);
      } catch (error) {
        setCartLoader(false);
        console.log("add to cart error", error);
      }
    }
  };

  // REMOVE FROM CART
  const removeCartData = async (sku) => {
    try {
      if (isTokenExpired(accessToken)) accessToken = await refreshAccessToken();

      await axios.delete(`${REMOVE_CART_DATA}/${sku}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
        withCredentials: true,
      });
    } catch (error) {
      toast.error(error.response.data.error);
    }
  };
  // Get cart data
  const [fetchCardLoader, setFetchCardLoader] = useState(true);
  const fetchCartData = async () => {
    try {
      if (accessToken && isTokenExpired(accessToken))
        accessToken = await refreshAccessToken();

      const response = await axios.get(GET_CART_DATA, {
        headers: { Authorization: `Bearer ${accessToken}` },
        withCredentials: true,
      });
      setCartData(response?.data.cartData.reverse());
      setFetchCardLoader(false);
    } catch (error) {
      setFetchCardLoader(false);
      setCartData(null);
    }
  };

  useEffect(() => {
    fetchCartData();
  }, []);

  const Commisions = (price) => {
    let CommisionPrice = 0;
    if (price < 200) return price;
    else if (price >= 200 && price < 500) CommisionPrice = price + price * 0.12;
    else if (price >= 500 && price < 1000)
      CommisionPrice = price + price * 0.08;
    else CommisionPrice = price + price * 0.07;
    return Math.round(CommisionPrice);
  };

  const [wishListData, setWishListData] = useState([]);
  const [FetchWishlistDataLoader, setFetchWishlistDataLoader] = useState(true);
  const FetchWishlistData = async () => {
    try {
      const response = await axios.get(GET_WISHLIST, {
        headers: { Authorization: `Bearer ${accessToken}` },
        withCredentials: true,
      });
      setWishListData(response?.data?.wishListData);
      setFetchWishlistDataLoader(false);
    } catch (error) {
      setFetchWishlistDataLoader(false);
      setWishListData(null);
    }
  };
  useEffect(() => {
    FetchWishlistData();
  }, []);

  const AddToWishList = async (sku) => {
    try {
      await axios.post(
        `${ADD_WISHLIST}`,
        {
          sku: sku,
        },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
          withCredentials: true,
        }
      );
      FetchWishlistData();
      // toast.success("Added item successfully");
    } catch (error) {
      toast.error("Something Went Wrong");
    }
  };
  const removedWishlistItem = async (sku) => {
    try {
      await axios.delete(`${DELETE_WISHLIST}/${sku}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
        withCredentials: true,
      });
      // toast.success("Removed item successfully");
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  return (
    <UserContext.Provider
      value={{
        isTokenExpired,
        accessToken,
        refreshAccessToken,
        itemCount,
        setItemCount,
        fetchCardLoader,
        cartData,
        setCartData,
        removeCartData,

        checkoutData,
        handleSignOut,
        setCheckoutData,
        cartLoader, // loader for add cart button
        handleAddToCart, // ADD CART FUNCTION
        isLoginOpen,
        setIsLoginOpen,
        isOtpModal,
        setIsOtpModal,
        Commisions,
        AddToWishList,
        wishListData,
        setWishListData,
        FetchWishlistDataLoader,
        FetchWishlistData,
        removedWishlistItem,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
