import React, { useContext, useState, useRef, useEffect } from "react";
import styles from "./Login.module.css";
import { RxCross1 } from "../assets/Icons";
import { UserContext } from "../context/userContext";
import axios from "axios";
import { SEND_OTP } from "../components/Apis";
import Loader from "../assets/spinnerbandhej.svg";
import { toast } from "react-toastify";

const LoginModal = ({ isOpen }) => {
  const { setIsOtpModal, setIsLoginOpen } = useContext(UserContext);
  const modalRef = useRef(null);

  const [buttonLoader, setButtonLoader] = useState(false);
  const [email, setEmail] = useState("");

  const CloseModal = () => {
    setIsLoginOpen(false);
    document.body.style.overflow = "auto";
  };

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      CloseModal();
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  if (!isOpen) return null;

  const SubmitForm = async (e) => {
    e.preventDefault();
    setButtonLoader(true);

    try {
      await axios.post(
        SEND_OTP,
        {
          email: email,
        },
        {
          withCredentials: true,
        }
      );
      setIsOtpModal(true);
      setIsLoginOpen(false);
      localStorage.setItem("BHUserEmail", email);
      setButtonLoader(false);
    } catch (error) {
      toast.error(error.response.data.message);
      setButtonLoader(false);
    }

    return true;
  };

  return (
    <>
      <div className={styles.modal}>
        <div ref={modalRef} className={styles.modalContent}>
          <button onClick={CloseModal} className={styles.closeButton}>
            <RxCross1 />
          </button>
          <img
            src="/assets/bhlogo.png"
            alt="Bandhej Hub"
            className={styles.logo}
          />
          <div className={styles.welcomeText}>
            <h3>Welcome to Bandhej Hub</h3>
            <p>Enter your email to continue shopping</p>
          </div>
          <form method="POST" onSubmit={SubmitForm}>
            {/* Email  */}
            <div className={styles.inputGroup}>
              <label htmlFor="email">
                Email address <span>*</span>{" "}
              </label>
              <input
                type="email"
                name="email"
                placeholder="Enter your email address"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            {buttonLoader ? (
              <img src={Loader} alt="Loading..." className={styles.loader} />
            ) : (
              <button className={styles.loginButton}>Send OTP</button>
            )}
          </form>
          <p className={styles.registerbtn}>
            By Confirming, you agree to bandhej Hub's Terms and Conditions and
            Privacy Policy.
          </p>
        </div>
      </div>
    </>
  );
};

export default LoginModal;
