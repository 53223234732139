import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Sidebar.module.css";
import {
  TfiHeart,
  PiShoppingCart,
  CiUser,
  LiaShippingFastSolid,
  SlPhone,
  AiOutlineMail,
} from "../../assets/Icons";
import { MdLogout } from "react-icons/md";
import { UserContext } from "../../context/userContext";

const Sidebar = ({ isOpen, onClose }) => {
  const { handleSignOut, accessToken, setIsLoginOpen } =
    useContext(UserContext);

  const sidebarRef = useRef(null);

  const navigate = useNavigate();
  const [listChange, setListChange] = useState(true);

  const ListChange = (value) => {
    if (value === "1") setListChange(true);
    else if (value === "2") setListChange(false);
  };
  const clickList = (value) => {
    if (value === "/") navigate("/");
    else if (value === "myorders") navigate("/myorders?status=All");
    else if (
      value === "Saree" ||
      value === "Dupatta" ||
      value === "Lehanga" ||
      value === "Dress"
    ) {
      navigate(`/${value}?page=1`);
    } else navigate(`/${value}`);

    document.body.style.overflow = "auto";
    onClose();
  };

  const openLoginModal = () => {
    document.body.style.overflow = "hidden";
    setIsLoginOpen(true);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, onClose]);

  return (
    <>
      <div
        className={`${styles.overlay} ${isOpen ? styles.open : ""}`}
        onClick={onClose}
      ></div>
      <div
        ref={sidebarRef}
        className={`${styles.sidebar} ${isOpen ? styles.open : ""}`}
      >
        <div>
          {/* Toggle Menu & Categories */}
          <div
            className={`${styles.navsideBarMenuList} ${styles.btnListNavbar}`}
          >
            <button
              onClick={() => setListChange(true)}
              className={listChange ? styles.activebtn : ""}
              aria-label="Open Menu"
            >
              Menu
            </button>
            <button
              onClick={() => setListChange(false)}
              className={!listChange ? styles.activebtn : ""}
              aria-label="Open Categories"
            >
              Categories
            </button>
          </div>

          {/* Sidebar Content */}
          {listChange ? (
            // Menu Section
            <div>
              <div
                className={styles.navsideBarMenuList}
                onClick={() => clickList("/")}
              >
                All Products
              </div>
              <div
                className={styles.navsideBarMenuList}
                onClick={() => clickList("myorders")}
              >
                <LiaShippingFastSolid />
                My Orders
              </div>
              <div
                className={styles.navsideBarMenuList}
                onClick={() => clickList("wishlist")}
              >
                <TfiHeart />
                Wishlist
              </div>
              <div
                className={styles.navsideBarMenuList}
                onClick={() => clickList("cart")}
              >
                <PiShoppingCart />
                Cart
              </div>

              {/* Account or Login Option */}
              {accessToken ? (
                <div
                  className={styles.navsideBarMenuList}
                  onClick={() => clickList("account")}
                >
                  <CiUser />
                  Account
                </div>
              ) : (
                <div
                  className={styles.navsideBarMenuList}
                  onClick={openLoginModal}
                >
                  <CiUser />
                  Login/Register
                </div>
              )}

              {/* Help Section */}
              <div
                className={styles.navsideBarMenuList}
                style={{
                  cursor: "auto",
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <p>Need Help?</p>
                <p>
                  <a href="tel:+917740930250" target="_blank" rel="noreferrer">
                    <SlPhone /> +917740930250
                  </a>
                </p>
                <p>
                  <a
                    href="mailto:bandhejhub@gmail.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <AiOutlineMail /> bandhejhub@gmail.com
                  </a>
                </p>
              </div>

              {/* Become a Seller */}
              <div className={styles.navsideBarMenuList}>
                <a
                  href="https://seller.bandhejhub.tech"
                  target="_blank"
                  rel="noreferrer"
                >
                  Become a Seller?
                </a>
              </div>
            </div>
          ) : (
            // Categories Section
            <div>
              <div
                className={styles.navsideBarMenuList}
                onClick={() => clickList("/")}
              >
                All Products
              </div>
              {["Saree", "Dupatta", "Lehanga", "Dress"].map((category) => (
                <div
                  key={category}
                  className={styles.navsideBarMenuList}
                  onClick={() => clickList(category)}
                >
                  {category}
                </div>
              ))}
            </div>
          )}
        </div>
        {/* Logout Option */}
        {accessToken && (
          <div className={styles.navsideBarMenuList} onClick={handleSignOut}>
            <MdLogout />
            Logout
          </div>
        )}
      </div>
    </>
  );
};

export default Sidebar;
