import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./ProductCard.module.css";
import { UserContext } from "../../context/userContext";
import saleCard from "../../assets/saleCard.svg";
import CartIcon from "../../assets/cart.svg";
import HeartIcon from "../../assets/heart.svg";
import RedHeartIcon from "../../assets/red-heart.svg";
import ViewIcon from "../../assets/view.svg";
import Loader from "../../assets/spinnerwhite.svg";
import { toast } from "react-toastify";
import FullStarIcon from "../../assets/RatingFullStarIcon.svg";
import BlankStarIcon from "../../assets/RatingBlankStarIcon.svg";
import axios from "axios";
import { GET_PRODUCT_REVIEWS } from "../Apis";
const ProductCard = ({ product }) => {
  const [reviews, setReviews] = useState([]);
  const [rating, setRating] = useState(0);

  useEffect(() => {
    if (reviews.length === 0) setRating(0);
    else {
      const totalRating = reviews.reduce(
        (sum, review) => sum + review.rating,
        0
      );
      const averageRating = totalRating / reviews.length;
      setRating(Math.round(averageRating));
    }
  }, [reviews]);
  // get variant reviews
  useEffect(() => {
    const getVariantReviews = async () => {
      try {
        const response = await axios.get(
          `${GET_PRODUCT_REVIEWS}/${product.variants[0].sku}`
        );
        setReviews(response.data.reviews);
      } catch (error) {
        console.error("Error fetching reviews:", error);
      }
    };
    getVariantReviews();
  }, []);
  const navigate = useNavigate();
  const {
    handleAddToCart,
    Commisions,
    AddToWishList,
    wishListData,
    removedWishlistItem,
    accessToken,
    setIsLoginOpen,
  } = useContext(UserContext);

  const [loading, setLoading] = useState(false);
  const [isInWishlist, setIsInWishlist] = useState(
    wishListData?.some(
      (item) => item.variant.productId === product.variants[0].productId
    )
  );

  const handleNavigate = () => {
    navigate(
      `/${product?.category}/${product?.name?.replace(/\s+/g, "-")}/${
        product?.productId
      }?variant=${product?.variants[0]?.sku}`
    );
  };

  const truncateText = (text) => {
    if (window.innerWidth <= 775) {
      if (text.length < 20) return text;
      return text.slice(0, 20) + "...";
    } else {
      if (text.length < 25) return text;
      return text.slice(0, 25) + "...";
    }
  };

  const handleAddToCartClick = async () => {
    setLoading(true);
    await handleAddToCart(1, product?.variants[0].sku);
    setLoading(false);
  };

  const handleWishlistClick = () => {
    if (!accessToken) {
      document.body.style.overflow = "hidden";
      setIsLoginOpen(true);
      toast.error("Please Login for add product in wishlist");
    } else {
      setIsInWishlist(!isInWishlist);
      if (isInWishlist) {
        removedWishlistItem(product?.variants[0].sku);
      } else {
        AddToWishList(product?.variants[0].sku);
      }
    }
  };

  return (
    <>
      <div className={styles.productCard}>
        {/* Icons on hover */}
        <div className={styles.iconContainer}>
          <button onClick={handleWishlistClick}>
            <img
              src={isInWishlist ? RedHeartIcon : HeartIcon}
              alt="Add to Wishlist"
            />
          </button>
          <button>
            <img src={ViewIcon} alt="View Product" />
          </button>
        </div>
        {/* {Discount tag } */}
        {product?.discount && (
          <p className={styles.productCardTag}>
            <span>{product?.discount}%</span>
            <img src={saleCard} alt="Sale" />
          </p>
        )}
        {/* Image  */}
        <div className={styles.productImageContainer} onClick={handleNavigate}>
          <img src={product?.variants[0]?.image} alt={product?.name} />
        </div>
        <div className={styles.productInfo}>
          {/* category  and stock*/}
          <div className={styles.stockCategory}>
            <div className={styles.productCategory}>{product?.category}</div>
            <div className={styles.productStock}>
              {product?.variants[0].stock ? (
                <span className={styles.InStock}>• In Stock</span>
              ) : (
                <span className={styles.OutStock}>• Out Stock</span>
              )}
            </div>
          </div>
          {/* product Name  */}
          <p className={styles.productName}>{truncateText(product?.name)}</p>
          {/* product review  */}
          <div className={styles.rating}>
            <div className={styles.ratingStar}>
              {[...Array(5)].map((_, index) => (
                <img
                  key={index}
                  src={index < rating ? FullStarIcon : BlankStarIcon}
                  alt="star"
                />
              ))}
            </div>

            <div>
              <p>({reviews.length} reviews)</p>
            </div>
          </div>
          {/* product price  */}
          <div className={styles.productInfoPrice}>
            <div>
              <p>₹{Commisions(product?.showPrice)}.00</p>
            </div>
            <div>
              <p>₹{product?.price}.00</p>
            </div>
          </div>
          {/* buttons  */}
          <div className={styles.productInfoPriceCartBtn}>
            <button onClick={handleAddToCartClick} disabled={loading}>
              {loading ? (
                <img src={Loader} alt="Loading..." />
              ) : (
                <>
                  <img src={CartIcon} alt="Add to Cart" />
                  Add To Cart
                </>
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductCard;
