import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/userContext";
import { GET_USER_PROFILE, UPDATE_USER_PROFILE } from "../Apis";
import styles from "./Profile.module.css";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { toast } from "react-toastify";
import EditIcon from "../../assets/EditIcon.svg";
import Loader from "../../assets/spinnerwhite.svg";

const Profile = () => {
  let { handleSignOut, isTokenExpired, refreshAccessToken, accessToken } =
    useContext(UserContext);

  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false); // Track edit mode
  const [editedName, setEditedName] = useState("");
  const [editedPhone, setEditedPhone] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);

  const fetchUserData = async () => {
    if (isTokenExpired(accessToken)) accessToken = await refreshAccessToken();
    try {
      const response = await axios.get(GET_USER_PROFILE, {
        headers: { Authorization: `Bearer ${accessToken}` },
        withCredentials: true,
      });
      setLoading(false);
      setProfile(response.data.user);
      setEditedName(response.data.user.name);
      setEditedPhone(response.data.user.phone);
    } catch (error) {
      toast.error("Something went wrong");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  const handleSaveChanges = async () => {
    if (editedPhone.length !== 10) {
      toast.error("Enter Correct Phone Number");
    }
    setIsUpdating(true);
    try {
      await axios.patch(
        UPDATE_USER_PROFILE,
        { name: editedName, phone: editedPhone },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
          withCredentials: true,
        }
      );
      setIsUpdating(false);
      setProfile({ ...profile, name: editedName, phone: editedPhone }); // Update state
      setIsEditing(false);
      toast.success("Profile updated successfully!");
    } catch (error) {
      setIsUpdating(false);
      toast.error("Failed to update profile.");
    }
  };

  if (loading) {
    return (
      <div className={styles.profileSkeleton}>
        {window.innerWidth > 550 ? (
          <>
            <Skeleton height={20} width={400} />
            <Skeleton height={20} width={400} />
            <Skeleton height={20} width={400} />
            <Skeleton height={20} width={400} />
            <Skeleton height={30} width={300} />
          </>
        ) : (
          <>
            <Skeleton height={25} width={300} />
            <Skeleton height={25} width={300} />
            <Skeleton height={20} width={300} />
            <Skeleton height={20} width={300} />
            <Skeleton height={25} width={250} />
          </>
        )}
      </div>
    );
  }

  return (
    <div>
      <div className={styles.accountInfo}>
        <ul>
          <li className={styles.accountInfoHeader}>
            <b>Account Information</b>
            <div onClick={() => setIsEditing(true)}>
              {" "}
              {/* Enable edit mode */}
              <img src={EditIcon} alt="edit" />
              <p>Edit Profile</p>
            </div>
          </li>
          <li>
            <b>Name</b>
            {isEditing ? (
              <div>
                <input
                  type="text"
                  value={editedName}
                  onChange={(e) => setEditedName(e.target.value.trimStart())}
                  placeholder="Enter your name"
                />
              </div>
            ) : (
              profile?.name
            )}
          </li>
          <li>
            <b>Email</b> {profile?.email} {/* Email remains unchanged */}
          </li>
          <li>
            <b>Phone</b>

            {isEditing ? (
              <div>
                <input
                  type="text" // Use text to prevent browser number increment buttons
                  value={editedPhone}
                  onChange={(e) => {
                    const value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                    if (value.length <= 10) {
                      setEditedPhone(value.trim()); // Ensure trimming & max length
                    }
                  }}
                  maxLength={10} // Prevents typing more than 10 characters
                  placeholder="Enter your phone number"
                />
              </div>
            ) : (
              profile?.phone
            )}
          </li>

          {isEditing ? (
            <div className={styles.editActions}>
              {/* Cancel Button */}
              <button
                className={styles.cancelButton}
                onClick={() => setIsEditing(false)}
              >
                Cancel
              </button>

              {/* Save Button */}
              {(!editedName && !editedPhone) ||
              (profile.name === editedName && profile.phone === editedPhone) ? (
                <button disabled className={styles.saveButtonDisabled}>
                  Save Changes
                </button>
              ) : (
                <button
                  onClick={handleSaveChanges}
                  className={styles.saveButton}
                >
                  {isUpdating ? (
                    <img src={Loader} alt="loading..." />
                  ) : (
                    "Save Changes"
                  )}
                </button>
              )}
            </div>
          ) : (
            <button onClick={handleSignOut}>Log Out</button>
          )}
        </ul>
      </div>
    </div>
  );
};

export default Profile;
